import React, { useEffect, useCallback } from 'react';
import { Grid, IconButton, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ordersListener } from '@ameroservices-platform/attraction-frontend/app/store/shared/userSlice';
import AccountTable from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/custom/account/tabs/AccountTable';
import OrderStatus from '@ameroservices-platform/shared/ui-components/status/OrderStatus';
import moment from 'moment-timezone';
import { Download, InfoOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';

export default function AccountTabOrders({ contentElementProps }) {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const user = useSelector(({ auth }) => auth.user);
	const orders = useSelector(({ shared }) => shared.user.orders) || [];

	useEffect(() => {
		if (user?.uid) {
			const unsubUserOrders = dispatch(ordersListener(user.uid));
			return () => {
				unsubUserOrders();
			};
		}
		return () => {};
	}, [dispatch, user]);

	const handleOrderDetails = useCallback(
		tableMeta => {
			const orderNumber = tableMeta.rowData[0];
			const order = orders.find(o => o.orderNumber === orderNumber);
			if (order) navigate(`/account?tab=orders&orderId=${order.orderUid}`);
		},
		[navigate, orders]
	);

	const handleDownload = useCallback(
		tableMeta => {
			const isProduction = process.env.NODE_ENV === 'production';
			const orderNumber = tableMeta.rowData[0];
			const order = orders.find(o => o.orderNumber === orderNumber);

			if (!order) return;

			const { organisationUid, orderUid, contactInfo } = order;
			const email = contactInfo?.billing?.email;
			if (!organisationUid || !orderUid || !email) return;

			const baseUrl = isProduction
				? `https://europe-west3-attraction-prod.cloudfunctions.net/mailPdf`
				: `http://localhost:5001/attraction-test/europe-west3/mailPdf`;

			window.open(`${baseUrl}/${organisationUid}/${orderUid}/${email}`);
		},
		[orders]
	);

	const columns = [
		{ name: 'orderNumber', label: t('ORDER_NUMBER'), options: { filter: true, sort: true } },
		{
			name: 'state',
			label: t('ORDER_STATUS'),
			options: {
				filter: true,
				sort: true,
				customBodyRender: value => <OrderStatus statusKey={value} />
			}
		},
		{
			name: 'createdAt',
			label: t('ORDER_TIMESTAMP'),
			options: {
				filter: false,
				sort: true,
				customBodyRender: value => moment(value).format('DD/MM/YYYY')
			}
		},
		{
			name: 'totals',
			label: t('TOTAL_PRICE'),
			options: {
				filter: false,
				sort: false,
				customBodyRender: value => `${value?.total} kr.`
			}
		},
		{
			name: 'orderNumber',
			label: t('ACTIONS'),
			options: {
				filter: false,
				sort: false,
				setCellHeaderProps: () => ({ style: { textAlign: 'right' } }),
				customBodyRender: (value, tableMeta) => (
					<div className="flex justify-start md:justify-end items-center pr-8">
						<Tooltip title={t('ACCOUNT_DOWNLOAD')}>
							<IconButton onClick={() => handleDownload(tableMeta)} className="p-2" aria-label="Download">
								<Download fontSize="small" />
							</IconButton>
						</Tooltip>
						<Tooltip title={t('ACCOUNT_SHOW_ORDER_DETAILS')}>
							<IconButton onClick={() => handleOrderDetails(tableMeta)}>
								<InfoOutlined fontSize="small" />
							</IconButton>
						</Tooltip>
					</div>
				)
			}
		}
	];

	return (
		<Grid container spacing={2} className="mb-48">
			<Grid item xs={12}>
				<Paper className="py-24 px-20">
					<Typography variant="h5">{t('ACCOUNT_TAB_ORDERS')}</Typography>
				</Paper>
			</Grid>

			<Grid item xs={12}>
				<Paper className="px-2">
					<AccountTable data={orders} columns={columns} contentElementProps={contentElementProps} />
				</Paper>
			</Grid>
		</Grid>
	);
}
