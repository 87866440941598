import {
	Alert,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Menu,
	MenuItem,
	TextField,
	Typography
} from '@mui/material';
import Icon from '@mui/material/Icon';
import React, { useEffect, useState } from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import {
	mergeThemes,
	selectModules,
	selectTheme
} from '@ameroservices-platform/attraction-frontend/app/store/shared/frontendSlice';
import QRCode from 'qrcode';
import ImageLoading from '@ameroservices-platform/attraction-frontend/app/ui-components/ImageLoading';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import LoadingButton from '@ameroservices-platform/attraction-frontend/app/ui-components/LoadingButton';
import { makeStyles } from '@mui/styles';
import getColor from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/shared/getColor';
import { selectMainTheme } from '@ameroservices-platform/attraction-frontend/app/store/fuse/settingsSlice';

export default function AccountQR({ name, ticketNumber, contentElementProps }) {
	const { t } = useTranslation();
	const mainTheme = useSelector(selectMainTheme);
	const theme = useSelector(selectTheme);
	const mergedTheme = mergeThemes(mainTheme, theme);
	const useStyles = makeStyles(_theme => ({
		button: {
			color: `${getColor(
				contentElementProps.buttonTextColor,
				contentElementProps.customButtonTextColor,
				mergedTheme
			)}`,
			backgroundColor: `${getColor(
				contentElementProps.buttonBackgroundColor,
				contentElementProps.customButtonBackgroundColor,
				mergedTheme
			)}`,
			'&:hover': {
				color: `${getColor(
					contentElementProps.buttonTextColorHover,
					contentElementProps.customButtonTextColorHover,
					mergedTheme
				)}`,
				backgroundColor: `${getColor(
					contentElementProps.buttonBackgroundColorHover,
					contentElementProps.customButtonBackgroundColorHover,
					mergedTheme
				)}`
			}
		}
	}));

	const [open, setOpen] = useState(false);
	const [qrCode, setQrCode] = useState(null);
	const [noQrCode, setNoQrCode] = useState(false);
	const classes = useStyles();

	useEffect(() => {
		if (!ticketNumber) {
			setNoQrCode(true);
			return;
		}
		QRCode.toDataURL(ticketNumber, { scale: 10 }, (err, url) => {
			setQrCode(url);
			setNoQrCode(false);
		});
	}, [ticketNumber]);

	return (
		<>
			{!qrCode && noQrCode && <div className={'bg-gray-500 w-64 h-64'}></div>}
			{!qrCode && !noQrCode && (
				<div>
					<CircularProgress color="primary" />
				</div>
			)}
			{qrCode && !noQrCode && <img src={qrCode} alt="QR Code" onClick={() => setOpen(true)} />}
			<Dialog
				maxWidth="xs"
				fullWidth
				open={open}
				onClose={() => setOpen(false)}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">{name}</DialogTitle>{' '}
				<DialogContent className={'flex items-center justify-center flex-col'}>
					<div>
						<img src={qrCode} alt="QR Code" />
					</div>
					<Typography className={'-mt-20'}>{ticketNumber}</Typography>{' '}
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => setOpen(false)}
						color="primary"
						variant="outlined"
						className={classes.button}
					>
						{t('CLOSE')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
