import React from 'react';
import MUIDataTable from 'mui-datatables';
import { useTranslation } from 'react-i18next';

export default function AccountTable({ data, columns, contentElementProps }) {
	const { t } = useTranslation();

	const getRowProps = (row, dataIndex) => ({
		key: row.id || dataIndex,
		style: {
			borderTop: window.innerWidth < 900 ? 'none' : '',
			borderBottom:
				dataIndex === data.length - 1 ? '' : window.innerWidth < 900 ? '4px solid rgba(0, 0, 0, 0.15)' : ''
		}
	});

	const options = {
		responsive: 'vertical',
		setRowProps: getRowProps,
		rowId: 'id',
		filterType: 'checkbox',
		selectableRows: 'none',
		download: false,
		print: false,
		filter: false,
		search: false,
		viewColumns: false,
		elevation: 0,
		textLabels: {
			body: { noMatch: '' },
			pagination: {
				next: t('PAGINATION_NEXT'),
				previous: t('PAGINATION_PREVIOUS'),
				rowsPerPage: t('PAGINATION_ROWS_PER_PAGE'),
				displayRows: t('PAGINATION_DISPLAY_ROWS')
			}
		},
		sortOrder: { name: 'state', direction: 'asc' }
	};

	return <MUIDataTable data={data} columns={columns} options={options} />;
}
