import { createSlice } from '@reduxjs/toolkit';
import firebaseService from '@ameroservices-platform/shared/services/frontendFirebase';
import orderState from '../../ui-components/states/orderState';

const userSlice = createSlice({
	name: 'userApp/user',
	initialState: {
		user: null,
		loading: false,
		orders: [],
		tickets: [],
		validations: {},
		subCustomerTickets: {},
		subscriptions: [],
		orderLines: {},
		subscriptionOrders: {},
		associatedCustomers: null
	},
	reducers: {
		setUser(state, action) {
			state.user = action.payload;
		},
		setLoading(state, action) {
			state.loading = action.payload;
		},
		setTickets(state, action) {
			state.tickets = action.payload;
		},
		setValidations(state, action) {
			state.validations[action.payload.ticketUid] = action.payload.validations;
		},
		setOrders(state, action) {
			state.orders = action.payload;
		},
		setSubscriptions(state, action) {
			state.subscriptions = action.payload;
		},
		setSubscriptionTypes(state, action) {
			state.subscriptionTypes = action.payload;
		},
		setAssociatedCustomers(state, action) {
			state.associatedCustomers = action.payload;
		},
		setSubscriptionOrders(state, action) {
			state.subscriptionOrders = { ...state.subscriptionOrders, ...action.payload };
		},
		setSubscriptionOrdersV2(state, action) {
			state.subscriptionOrders[action.payload.subscriptionUid] = action.payload.orders;
		},
		setOrderLines(state, action) {
			const orderLines = { ...state.orderLines };

			orderLines[action.payload.orderUid] = [];

			action.payload.orderLines.forEach(orderLine => {
				orderLines[action.payload.orderUid].push(orderLine);
			});
			state.orderLines = orderLines;
		},
		setSubCustomerTickets(state, action) {
			state.subCustomerTickets[action.payload.customerUid] = action.payload.tickets;
		}
	}
});

export const {
	setUser,
	setLoading,
	setOrders,
	setOrderLines,
	setTickets,
	setValidations,
	setSubscriptions,
	setSubscriptionTypes,
	setAssociatedCustomers,
	setSubscriptionOrders,
	setSubCustomerTickets,
	setSubscriptionOrdersV2
} = userSlice.actions;

export default userSlice.reducer;

export const orderLinesListener = (customerUid, orderUid) => dispatch => {
	const db = firebaseService.getOrganisationRootDB();
	return (
		db
			.collection('customers')
			.doc(customerUid)
			.collection('orders')
			.doc(orderUid)
			.collection('orderLines')
			// .where('state', '==', 'complete') TODO
			.onSnapshot(
				docs => {
					const orderLines = docs.docs.reduce((acc, cur) => {
						acc.push({ ...cur.data(), id: cur.id });
						return acc;
					}, []);

					dispatch(setOrderLines({ orderLines, orderUid }));
				},
				error => console.error('orderLinesListener', { customerUid, error })
			)
	);
};

export const ordersListener = customerUid => dispatch => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('customers')
		.doc(customerUid)
		.collection('orders')
		.where('deleted', '==', false)
		.where('state', 'in', ['complete', 'pendingPayment', 'error', 'refunded'])
		.onSnapshot(
			docs => {
				const orders = docs.docs.reduce((acc, cur) => {
					const data = cur.data();
					data.payments = data.payments.map(payment => ({
						...payment,
						updatedAt: payment.updatedAt ? payment.updatedAt.toDate().getTime() : undefined
					}));
					acc.push({
						...data,
						createdAt: data.createdAt ? data.createdAt.toDate().getTime() : undefined,
						updatedAt: data.updatedAt ? data.updatedAt.toDate().getTime() : undefined,
						id: cur.id
					});
					return acc;
				}, []);

				orders.sort((a, b) => b.createdAt - a.createdAt);
				dispatch(setOrders(orders));
			},
			error => console.error('ordersListener', { customerUid, error })
		);
};

export const ticketsForSubCustomerListener = customerUid => dispatch => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('customers')
		.doc(customerUid)
		.collection('customer_tickets')
		.where('deleted', '==', false)
		.onSnapshot(
			docs => {
				const tickets = docs.docs.reduce((acc, cur) => {
					const ticket = cur.data();
					delete ticket.validations;
					if (ticket.event) {
						ticket.event.start = ticket.event.start ? ticket.event.start.toDate().getTime() : undefined;
						ticket.event.end = ticket.event.end ? ticket.event.end.toDate().getTime() : undefined;
					}
					acc.push({
						...ticket,
						updatedAt: ticket.updatedAt ? ticket.updatedAt.toDate().getTime() : undefined,
						id: cur.id
					});
					return acc;
				}, []);
				tickets.sort((a, b) => b.issueDate - a.issueDate);
				dispatch(setSubCustomerTickets({ customerUid, tickets }));
			},
			error => console.error('ticketsListener', { customerUid, error })
		);
};

export const ticketsListener = customerUid => dispatch => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('customers')
		.doc(customerUid)
		.collection('customer_tickets')
		.where('deleted', '==', false)
		.onSnapshot(
			docs => {
				const tickets = docs.docs.reduce((acc, cur) => {
					const ticket = cur.data();
					delete ticket.validations;
					if (ticket.event) {
						ticket.event.start = ticket.event.start ? ticket.event.start.toDate().getTime() : undefined;
						ticket.event.end = ticket.event.end ? ticket.event.end.toDate().getTime() : undefined;
					}
					acc.push({
						...ticket,
						updatedAt: ticket.updatedAt ? ticket.updatedAt.toDate().getTime() : undefined,
						id: cur.id
					});
					return acc;
				}, []);
				tickets.sort((a, b) => b.issueDate - a.issueDate);
				dispatch(setTickets(tickets));
			},
			error => console.error('ticketsListener', { customerUid, error })
		);
};

export const validationsListener = (customerUid, ticketUid) => dispatch => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('customers')
		.doc(customerUid)
		.collection('customer_tickets')
		.doc(ticketUid)
		.collection('validations')
		.where('deleted', '==', false)
		.where('success', '==', true)
		.where('type', '==', 'use')
		.onSnapshot(
			docs => {
				const validations = docs.docs.reduce((acc, cur) => {
					const validation = cur.data();
					validation.date = validation.date ? validation.date.toDate().getTime() : undefined;
					acc.push({
						...validation,
						id: cur.uid
					});
					return acc;
				}, []);
				dispatch(setValidations({ ticketUid, validations }));
			},
			error => console.error('validationsListener', { customerUid, error })
		);
};

export const subscriptionsListener = customerUid => dispatch => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('customers')
		.doc(customerUid)
		.collection('customer_subscriptions')
		.where('deleted', '==', false)
		.onSnapshot(
			docs => {
				let subscriptions = docs.docs.reduce((acc, cur) => {
					acc.push({ ...cur.data(), id: cur.id });
					return acc;
				}, []);

				subscriptions = subscriptions.map(subscription => ({
					...subscription,
					nextPeriodStart: subscription.nextPeriodStart
						? subscription.nextPeriodStart.toDate().getTime()
						: undefined,
					start: subscription.start ? subscription.start.toDate().getTime() : undefined,
					end: subscription.end ? subscription.end.toDate().getTime() : undefined
				}));
				subscriptions.sort((a, b) => b.start - a.start);

				dispatch(setSubscriptions(subscriptions));
			},
			error => console.error('subscriptionsListener', { customerUid, error })
		);
};

export const subscriptionTypesListener = () => dispatch => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('subscriptionTypes')
		.where('deleted', '==', false)
		.onSnapshot(query => {
			dispatch(setSubscriptionTypes(query.docs.map(doc => ({ ...doc.data(), id: doc.id }))));
		});
};

export const ordersListeners = (customerUid, subscriptionUid) => dispatch => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('customers')
		.doc(customerUid)
		.collection('orders')
		.where('subscriptionUid', '==', subscriptionUid)
		.onSnapshot(query => {
			dispatch(
				setSubscriptionOrdersV2({
					subscriptionUid: subscriptionUid,
					orders: query.docs.map(doc => ({ ...doc.data(), id: doc.id }))
				})
			);
		});
};

export const orderListeners = (customerUid, subscriptionUids) => dispatch => {
	const db = firebaseService.getOrganisationRootDB();
	const unsubs = subscriptionUids.map(subscriptionUid =>
		db
			.collection('customers')
			.doc(customerUid)
			.collection('orders')
			.where('subscriptionUid', '==', subscriptionUid)
			.where('state', '==', orderState.PENDING_PAYMENT)
			.orderBy('createdAt', 'desc')
			.limit(1)
			.onSnapshot(
				docs => {
					const orders = docs.docs.reduce((acc, cur) => {
						const data = cur.data();
						acc[data.subscriptionUid] = { ...cur.data(), id: cur.id };
						return acc;
					}, {});

					dispatch(setSubscriptionOrders(orders));
				},
				error => console.error('orderListeners', { customerUid, error })
			)
	);

	return unsubs;
};

export const getEventsByUids = async eventUids => {
	if (!eventUids) {
		return {};
	}
	const db = firebaseService.getRootDB();
	const eventDocs = await Promise.all(
		eventUids
			.filter(eventUid => eventUid)
			.map(eventUid => {
				return db
					.collectionGroup('events')
					.where('organisationUid', '==', firebaseService.getOrganisationId())
					.where('deleted', '==', false)
					.where('uid', '==', eventUid)
					.get();
			})
	);
	const es = {};
	eventDocs.forEach(eventDoc => {
		eventDoc.forEach(o => {
			const data = o.data();
			es[o.id] = { ...data, id: o.id, start: data.start.toMillis(), end: data.end.toMillis() };
		});
	});

	return es;
};

export const associatedCustomersListener = customerId => dispatch => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('customers')
		.where('deleted', '==', false)
		.where('isAssociated', '==', true)
		.where('associatedCustomer', '==', customerId)
		.onSnapshot(
			querySnapshot => {
				const customers = [];
				querySnapshot.forEach(doc => {
					const docData = doc.data();
					customers.push({
						...docData,
						id: doc.id
					});
				});
				dispatch(setAssociatedCustomers(customers));
			},
			error => console.error('associatedCustomersListener', { customerId, error })
		);
};

export const userListener = customerUid => dispatch => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('customers')
		.doc(customerUid)
		.onSnapshot(
			q => {
				dispatch(setUser({ ...q.data(), id: q.id, uid: q.id }));
			},
			error => console.error('userListener', { customerUid, error })
		);
};

export const updateUser = (customerUid, data) => async dispatch => {
	dispatch(setLoading(true));
	const db = firebaseService.getOrganisationRootDB();
	await db.collection('customers').doc(customerUid).update(data);
	dispatch(setLoading(false));
};

export const updateSubscriptionPaymentType = async (subscriptionUid, paymentType) => {
	const response = await firebaseService.callFunctionByName('organisationCustomerSubscriptionsSetPaymentType', {
		subscriptionUid,
		paymentType
	});
	return response.data;
};

export const createSubscriptionMandate = async subscriptionUid => {
	const response = await firebaseService.callFunctionByName(
		'organisationCustomerSubscriptionsCreateSubscriptionMandate',
		{
			subscriptionUid
		}
	);
	return response.data;
};

export const cancelSubscriptionPayment = async subscriptionUid => {
	const response = await firebaseService.callFunctionByName(
		'organisationCustomerSubscriptionsCancelSubscriptionPayment',
		{
			subscriptionUid
		}
	);
	return response.data;
};

export const updateCustomerFunction = (customerUid, data) => async dispatch => {
	dispatch(setLoading(true));
	await firebaseService.callFunctionByName('organisationCustomerUpdateCustomer', {
		customerUid,
		...data,
		frontend: true
	});
	dispatch(setLoading(false));
};

export const sendSubscriptionConfirmationEmail = url => async dispatch => {
	dispatch(setLoading(true));
	console.log(url);
	await firebaseService.callFunctionByName('organisationCustomerSendSubscriptionConfirmationMail', { url });
	dispatch(setLoading(false));
};

export const sendConfirmationEmail = autoSend => async dispatch => {
	dispatch(setLoading(true));
	const currentLanguageLocalStorage = localStorage.getItem('language')
		? JSON.parse(localStorage.getItem('language'))
		: null;
	await firebaseService.callFunctionByName('organisationCustomerSendConfirmationMail', {
		autoSend,
		language:
			currentLanguageLocalStorage && currentLanguageLocalStorage.isoCode
				? currentLanguageLocalStorage.isoCode
				: null
	});
	dispatch(setLoading(false));
};

export const confirmEmail = oobCode => async dispatch => {
	dispatch(setLoading(true));
	await firebaseService.callFunctionByName('organisationCustomerConfirmEmail', { oobCode });
	dispatch(setLoading(false));
};
export const activateUser = (oobCode, password) => async dispatch => {
	dispatch(setLoading(true));
	await firebaseService.callFunctionByName('organisationCustomerActivateUser', { oobCode, password });
	dispatch(setLoading(false));
};
export const updateCustomerByToken = (token, contactInfo) => async dispatch => {
	dispatch(setLoading(true));
	const resp = await firebaseService
		.callFunctionByName('organisationCustomerUpdateCustomerByToken', {
			token,
			contactInfo
		})
		.then(response => response.data)
		.catch(() => false);
	dispatch(setLoading(false));
	return resp;
};
export const getUserByToken = async token => {
	const resp = await firebaseService.callFunctionByName('organisationCustomerGetUserByToken', {
		token
	});
	return resp.data;
};

export const verifyCustomer = async email => {
	const resp = await firebaseService.callFunctionByName('organisationCustomerVerifyCustomer', { email });
	return resp.data;
};

export const selectUser = state => state.shared.user.user;
export const selectTickets = state => state.shared.user.tickets;
export const selectOrders = state => state.shared.user.orders;
export const selectSubscriptions = state => state.shared.user.subscriptions;
export const selectSubscriptionOrders = state => state.shared.user.subscriptionOrders;
export const selectLoading = state => state.shared.user.loading;
