const orderState = {
	CART: 'cart',
	DRAFT: 'draft',
	FLEXPOS_DRAFT: 'flexposDraft',
	PENDING_PAYMENT: 'pendingPayment',
	PENDING_CAPTURE: 'pendingCapture',
	SCHEDULED_PAYMENT: 'scheduledPayment',
	REFUNDED: 'refunded',
	PARTIALLY_REFUNDED: 'partiallyRefunded',
	PROCESSING: 'processing',
	COMPLETED: 'complete',
	CLOSED: 'closed',
	CANCELED: 'canceled',
	HOLD: 'hold',
	PAYMENT_REVIEW: 'paymentReview',
	RESERVED: 'reserved',
	ERROR: 'error',
	UNKNOWN: 'unknown',
};

export const orderStateTranslate = {
	cart: 'Kurv',
	draft: 'Kladde',
	[orderState.FLEXPOS_DRAFT]: 'FlexPOS Kladde',
	pendingPayment: 'Afventer Betaling',
	[orderState.PENDING_CAPTURE]: 'Afventer Betalingstrækning',
	[orderState.SCHEDULED_PAYMENT]: 'Planlagt Betaling',
	refunded: 'Refunderet',
	[orderState.PARTIALLY_REFUNDED]: 'Delvist Refunderet',
	processing: 'Behandler',
	complete: 'Gennemført',
	closed: 'Lukket',
	canceled: 'Annulleret',
	hold: 'På Hold',
	paymentReview: 'Gennemgå Betaling',
	reserved: 'Reserveret',
	error: 'Fejl',
	unknown: 'Ukendt',
};

export default orderState;
