import _ from 'lodash';
import clsx from 'clsx';
import React from 'react';
import orderState, { orderStateTranslate } from '@ameroservices-platform/shared/enums/orderState';

export const orderStatuses = [
	{
		key: orderState.CART,
		name: orderStateTranslate[orderState.CART],
		color: 'bg-green-800 text-white',
		exclude: true,
	},
	{
		key: orderState.DRAFT,
		name: orderStateTranslate[orderState.DRAFT],
		color: 'bg-orange-200 text-white',
	},
	{
		key: orderState.FLEXPOS_DRAFT,
		name: orderStateTranslate[orderState.FLEXPOS_DRAFT],
		color: 'bg-orange-200 text-white',
	},
	{
		key: orderState.PENDING_PAYMENT,
		name: orderStateTranslate[orderState.PENDING_PAYMENT],
		color: 'bg-yellow text-black',
	},
	{
		key: orderState.SCHEDULED_PAYMENT,
		name: orderStateTranslate[orderState.SCHEDULED_PAYMENT],
		color: 'bg-yellow text-black'
	},
	{
		key: orderState.PENDING_CAPTURE,
		name: orderStateTranslate[orderState.PENDING_CAPTURE],
		color: 'bg-orange text-white',
	},
	{
		key: orderState.REFUNDED,
		name: orderStateTranslate[orderState.REFUNDED],
		color: 'bg-blue-600 text-white',
	},
	{
		key: orderState.PARTIALLY_REFUNDED,
		name: orderStateTranslate[orderState.PARTIALLY_REFUNDED],
		color: 'bg-blue-300 text-white',
	},
	{
		key: orderState.PROCESSING,
		name: orderStateTranslate[orderState.PROCESSING],
		color: 'bg-orange text-white',
	},
	{
		key: orderState.COMPLETED,
		name: orderStateTranslate[orderState.COMPLETED],
		color: 'bg-green text-white',
	},
	{
		key: orderState.CLOSED,
		name: orderStateTranslate[orderState.CLOSED],
		color: 'bg-red text-white',
	},
	{
		key: orderState.CANCELED,
		name: orderStateTranslate[orderState.CANCELED],
		color: 'bg-red-700 text-white',
	},
	{
		key: orderState.HOLD,
		name: orderStateTranslate[orderState.HOLD],
		color: 'bg-blue-700 text-white',
	},
	{
		key: orderState.PAYMENT_REVIEW,
		name: orderStateTranslate[orderState.PAYMENT_REVIEW],
		color: 'bg-purple-700 text-white',
	},
	{
		key: orderState.RESERVED,
		name: orderStateTranslate[orderState.RESERVED],
		color: 'bg-blue text-white',
	},
	{
		key: orderState.ERROR,
		name: orderStateTranslate[orderState.ERROR],
		color: 'bg-red text-white',
	},
	{
		key: orderState.UNKNOWN,
		name: orderStateTranslate[orderState.UNKNOWN],
		color: 'bg-red text-white',
	},
];

export function getOrderStatusFromKey(key) {
	return orderStatuses.find((status) => status.key === key) || orderState.UNKNOWN; // default til "Unknown"
}

function OrderStatus(props) {
	const orderStatusObj =
		_.find(orderStatuses, { key: props.statusKey }) || _.find(orderStatuses, { key: orderState.UNKNOWN });

	return (
		<span className={clsx('inline text-12 p-4 rounded truncate', orderStatusObj.color, props.className)}>
			{orderStatusObj.name}
		</span>
	);
}

export default OrderStatus;
