import React, { useEffect, useMemo } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AccountTable from '@ameroservices-platform/attraction-frontend/app/main/apps/content/cmsContent/cmsComponents/custom/account/tabs/AccountTable';
import moment from 'moment-timezone';
import {
	ticketsListener,
	validationsListener
} from '@ameroservices-platform/attraction-frontend/app/store/shared/userSlice';
import { useDispatch, useSelector } from 'react-redux';

export default function AccountTabStatistics({ contentElementProps }) {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const user = useSelector(({ auth }) => auth.user);
	const tickets = useSelector(({ shared }) => shared.user.tickets);
	const validations = useSelector(({ shared }) => shared.user.validations);

	useEffect(() => {
		if (user?.uid) {
			const unsubFuncEventTickets = dispatch(ticketsListener(user.uid));
			return () => {
				unsubFuncEventTickets();
			};
		}
		return () => {};
	}, [dispatch, user]);

	useEffect(() => {
		if (user?.uid && tickets.length > 0) {
			const unsubscribeFunctions = tickets.map(ticket => dispatch(validationsListener(user.uid, ticket.id)));
			return () => {
				unsubscribeFunctions.forEach(unsub => unsub());
			};
		}
	}, [dispatch, user, tickets]);

	const data = useMemo(
		() =>
			Object.values(validations)
				.flat()
				.map(validation => {
					const ticket = tickets.find(t => t.id === validation.ticketUid);

					return {
						id: validation.uid,
						ticketNumber: ticket.ticketNumber,
						type: ticket.subscriptionUid ? t('MEMBERSHIP_CARD') : t('TICKET'),
						date: validation.date
					};
				})
				.filter(Boolean)
				.sort((a, b) => b.date - a.date),
		[validations, tickets]
	);

	const columns = [
		{ name: 'ticketNumber', label: t('TICKET_NUM'), options: { filter: false, sort: true } },
		{ name: 'type', label: t('TYPE'), options: { filter: true, sort: true } },
		{
			name: 'date',
			label: t('ORDER_VISIT_TIMESTAMP'),
			options: {
				filter: false,
				sort: true,
				customBodyRender: value => moment(value).format('DD/MM/YYYY HH:mm')
			}
		}
	];

	return (
		<Grid container spacing={2} className="mb-48">
			<Grid item xs={12}>
				<Paper className="py-24 px-20">
					<Typography variant="h5">{t('ACCOUNT_TAB_STATISTICS')}</Typography>
				</Paper>
			</Grid>

			<Grid item xs={12}>
				<Paper className="px-2">
					<AccountTable data={data} columns={columns} contentElementProps={contentElementProps} />
				</Paper>
			</Grid>
		</Grid>
	);
}
