import _ from 'lodash';
import clsx from 'clsx';
import React from 'react';
import ticketState, { ticketStateTranslate } from '@ameroservices-platform/shared/enums/ticketState';

export const ticketStatuses = [
	{
		key: ticketState.ISSUED,
		name: ticketStateTranslate[ticketState.ISSUED],
		color: 'bg-green text-white',
	},
	{
		key: ticketState.DRAFT,
		name: ticketStateTranslate[ticketState.DRAFT],
		color: 'bg-red text-white',
	},
	{
		key: ticketState.USED,
		name: ticketStateTranslate[ticketState.USED],
		color: 'bg-gray text-white',
	},
	{
		key: ticketState.EXPIRED,
		name: ticketStateTranslate[ticketState.EXPIRED],
		color: 'bg-gray text-white',
	},
	{
		key: ticketState.RESERVED,
		name: ticketStateTranslate[ticketState.RESERVED],
		color: 'bg-blue text-white',
	},
	{
		key: ticketState.BLOCKED,
		name: ticketStateTranslate[ticketState.BLOCKED],
		color: 'bg-red text-white',
	},
	{
		key: ticketState.UNKNOWN,
		name: ticketStateTranslate[ticketState.UNKNOWN],
		color: 'bg-red text-white',
	},
];

export function getTicketStatusFromKey(key) {
	return ticketStatuses.find((status) => status.key === key) || ticketState.UNKNOWN; // default til "Unknown"
}

function TicketStatus(props) {
	const orderStatusObj =
		_.find(ticketStatuses, { key: props.statusKey }) || _.find(ticketStatuses, { key: ticketState.UNKNOWN });

	return (
		<div className={clsx('inline text-12 p-4 rounded truncate', orderStatusObj.color)}>{orderStatusObj.name}</div>
	);
}

export default TicketStatus;
