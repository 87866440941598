import { Container, Typography } from '@mui/material';
import React from 'react';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';

export default function AccountOrderDetailCard({ title, subtitle }) {
	return (
		<Card className="flex items-center justify-center">
			<CardContent className="relative h-full" style={{ padding: '16px' }}>
				<Container className="flex flex-col gap-2 items-center justify-center">
					<Typography variant="h5">{title}</Typography>
					<Typography variant="body2">{subtitle}</Typography>
				</Container>
			</CardContent>
		</Card>
	);
}
