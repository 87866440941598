const ticketState = {
	DRAFT: 'draft',
	ISSUED: 'issued',
	BLOCKED: 'blocked',
	RESERVED: 'reserved',
	EXPIRED: 'expired',
	USED: 'used',
	UNKNOWN: 'unknown',
};
export const ticketStateTranslate = {
	draft: 'Kladde',
	issued: 'Udstedt',
	blocked: 'Blokkeret',
	reserved: 'Reserveret',
	used: 'Brugt',
	expired: 'Udløbet',
	unknown: 'Ukendt',
};

export default ticketState;
