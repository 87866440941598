import AvatarWebcam from '@ameroservices-platform/attraction-frontend/app/ui-components/AvatarWebcam';
import {
	updateCustomerFunction,
	updateUser
} from '@ameroservices-platform/attraction-frontend/app/store/shared/userSlice';
import Grid from '@mui/material/Grid';
import TextFieldForm from '@ameroservices-platform/shared/ui-components/fields/TextFieldForm';
import { Autocomplete, Checkbox, FormControlLabel, Paper, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
	selectCountries,
	selectDisableProfilePictureConfirmation,
	selectHideProfilePicture,
	selectModules
} from '@ameroservices-platform/attraction-frontend/app/store/shared/frontendSlice';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import SelectForm from '@ameroservices-platform/shared/ui-components/fields/SelectForm';
import LoadingButton from '@mui/lab/LoadingButton';

export default function AccountTabInformation() {
	const flags = useFlags();
	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user);
	const countries = useSelector(selectCountries);
	const modules = useSelector(selectModules);
	const hideProfilePicture = useSelector(selectHideProfilePicture);
	const disableProfilePictureConfirmation = useSelector(selectDisableProfilePictureConfirmation);
	const { watch, control, handleSubmit, formState, reset, setValue } = useForm({
		mode: 'onChange',
		defaultValues: {
			firstname: '',
			lastname: '',
			email: '',
			phone: '',
			postalCode: '',
			city: '',
			address: '',
			newsletterOptIn: false,
			countryCode: null
		}
	});
	const form = watch();
	const { isValid, isSubmitting, isDirty } = formState;
	const { t } = useTranslation();

	useEffect(() => {
		if (user?.contactInfo?.billing) {
			reset({ ...user.contactInfo.billing, newsletterOptIn: user.newsletterOptIn ?? false });
		}
	}, [user, reset]);

	async function onSubmit(data) {
		if (isFormValid && user && user.id) {
			const d = { contactInfo: {} };
			Object.entries(data).forEach(([key, value]) => {
				d.contactInfo[key] = value;
			});

			if (flags.emailSubscription) {
				d.newsletterOptIn = newsletterOptIn;
			}
			if (flags.salesToOtherCountries) {
				d.contactInfo.countryCode = countryCode;
			}

			await dispatch(updateCustomerFunction(user.id, d));
			setOpen(false);
		}
	}

	return (
		<>
			<Paper
				className={clsx(
					'py-24 px-6 mb-48 md:pr-20 pl-20',
					!hideProfilePicture && 'md:pl-6',
					hideProfilePicture && 'md:pl-36'
				)}
			>
				<Typography className="mb-20 pl-32 md:text-left" variant="h5">
					{t('ACCOUNT_TAB_INFORMATION')}
				</Typography>
				<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
					<div className="flex justify-between flex-col md:flex-row">
						{!hideProfilePicture && (
							<div className={`w-full md:w-2/6 md:flex-grow`}>
								<div className="flex items-center flex-col w-full h-full flex-grow mb-20 md:mb-0">
									<AvatarWebcam
										onAvatarChange={imageUrl => {
											const d = {};
											if (disableProfilePictureConfirmation) {
												d['data.photoURL'] = imageUrl;
											} else {
												d['data.photoWaiting'] = imageUrl;
											}
											dispatch(updateUser(user.id, d));
										}}
										src={user && user.data ? user.data.photoURL || user.data.photoWaiting : null}
										disabled={user && user.data && !!user.data.photoURL}
									/>
								</div>
							</div>
						)}

						<Grid
							container
							spacing={2}
							className={clsx(`sm:max-w-screen w-full pb-4`, !hideProfilePicture && 'md:w-4/6')}
						>
							<Grid item xs={6} sm={6}>
								<TextFieldForm
									control={control}
									id="firstname"
									name="firstname"
									label={t('FIRST_NAME')}
									validations={{
										minLength: 2
									}}
									validationErrors={{
										minLength: t('FIRST_NAME_MIN_2_CHAR')
									}}
									fullWidth
									required
									disabled
								/>
							</Grid>
							<Grid item xs={6} sm={6}>
								<TextFieldForm
									control={control}
									id="lastname"
									name="lastname"
									label={t('LAST_NAME')}
									validations={{
										minLength: 2
									}}
									validationErrors={{
										minLength: t('LAST_NAME_MIN_2_CHAR')
									}}
									fullWidth
									required
									disabled
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<TextFieldForm
									control={control}
									id="address"
									name="address"
									label={t('ADDRESS')}
									validations={{
										minLength: 2
									}}
									validationErrors={{
										minLength: t('ADDRESS_MIN_2_CHAR')
									}}
									fullWidth
									required
								/>
							</Grid>

							<Grid item xs={6} sm={6}>
								<TextFieldForm
									control={control}
									id="postalCode"
									name="postalCode"
									label={t('POSTAL_CODE')}
									validations={
										!flags.salesToOtherCountries || form.countryCode === 'DK'
											? {
													minLength: 3,
													maxLength: 4
											  }
											: {
													minLength: 3,
													maxLength: 8
											  }
									}
									validationErrors={
										!flags.salesToOtherCountries || form.countryCode === 'DK'
											? {
													minLength: t('POSTAL_CODE_LENGTH'),
													maxLength: t('POSTAL_CODE_LENGTH')
											  }
											: {
													minLength: t('POSTAL_CODE_LENGTH'),
													maxLength: t('POSTAL_CODE_NUMERIC')
											  }
									}
									fullWidth
									required
								/>
							</Grid>
							<Grid item xs={6} sm={6}>
								<TextFieldForm
									control={control}
									id="city"
									name="city"
									label={t('CITY')}
									validations={{
										minLength: 1
									}}
									validationErrors={{
										minLength: t('CITY_LENGTH')
									}}
									fullWidth
									required
								/>
							</Grid>
							<Grid item xs={6} sm={6}>
								<TextFieldForm
									control={control}
									id="email"
									name="email"
									label="Email"
									validations={{
										isEmail: true
									}}
									validationErrors={{
										isEmail: t('NOT_VALID_EMAIL')
									}}
									fullWidth
									disabled
									enableTrim
								/>
							</Grid>
							<Grid item xs={6} sm={6}>
								<TextFieldForm
									control={control}
									id="phone"
									name="phone"
									label={t('PHONE_NUMBER')}
									validations={
										!flags.salesToOtherCountries || form.countryCode === 'DK'
											? {
													minLength: 8,
													maxLength: 11
											  }
											: {
													minLength: 6,
													maxLength: 20
											  }
									}
									validationErrors={
										!flags.salesToOtherCountries || form.countryCode === 'DK'
											? {
													minLength: t('PHONE_LENGTH'),
													maxLength: t('PHONE_LENGTH')
											  }
											: {
													minLength: t('PHONE_LENGTH'),
													maxLength: t('PHONE_LENGTH')
											  }
									}
									fullWidth
									required
								/>
							</Grid>
							{flags.salesToOtherCountries && (
								<Grid item xs={12}>
									<SelectForm
										control={control}
										fullWidth
										id="countryCode"
										name="countryCode"
										options={Object.entries(countries).map(([key, country]) => ({
											value: key,
											label: country.name
										}))}
										openOnFocus
										required
									/>
								</Grid>
							)}

							{flags.emailSubscription && modules && (modules.mailchimp || modules.newsletterSignup) && (
								<Grid item xs={12}>
									<FormControlLabel
										className="whitespace-no-wrap"
										control={
											<Checkbox
												name="newsletterOptIn"
												checked={form.newsletterOptIn}
												onChange={async event => {
													setValue('newsletterOptIn', event.target.checked);
												}}
												color="primary"
											/>
										}
										label={
											<Typography className="flex flex-col sm:flex-row items-start sm:items-center">
												{t('NEWSLETTER_SIGNUP')}
											</Typography>
										}
									/>
								</Grid>
							)}
							<Grid item xs={12}>
								<LoadingButton
									type="submit"
									fullWidth
									className={'w-full'}
									variant={isDirty ? 'contained' : 'outlined'}
									loading={isSubmitting}
									disabled={!isValid}
								>
									{t('UPDATE_PROFILE')}
								</LoadingButton>
							</Grid>
						</Grid>
					</div>
				</form>
			</Paper>
		</>
	);
}
